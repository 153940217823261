<template>
  <v-container class="mt-5 main-content" >
    <div class="Title " >
      <h1 class="text-h5 d-flex">  {{this.contentItem.title}} <v-btn icon @click="slide=true;"><v-icon>{{icons.informationOutline}}</v-icon></v-btn>
      
       <!-- <v-col cols="6" class="ma-0 ml-auto pa-0">
          <StorageBar
             ref="storageChild"
            :item="$store.state.user_model.userData"
            :data="storageData"
            :loading="loading"
            onlyType="content"
            style="width:100%; max-width:1400px; height:20px;"
          />
        </v-col> -->
      </h1>
       
    </div>
    <ContentsTable :contentItem="contentItem" /> 

    
    <v-dialog 
      v-model="slide"
      max-width="400px" 
      width="98%"
      style="z-index:2001;"  
    >
      <CarouselTutorial
        :type="contentItem.type"
        v-on:closeEmit="slide=false"
      />

      <!-- <v-btn   color="blue" class="white--text mt-1"  @click="slide=false;">Close</v-btn> -->
    </v-dialog>
    
    <!-- <section>
      <div class="text-center">
        <div id="play-sound">
          <div id="play-sound-left" >
            <p id="play-sound-name">  </p>
          </div>
          <div id="play-sound-center" >
            <audio style="width:100%;" controls controlslist="nodownload" id="audio-view"></audio>
          </div>
          <div id="play-sound-right" >
            <div style="margin:auto 10px auto auto;display:flex; flex-wrap:nowrap; ">
              <a id="download-btn" style="display:none;"><i class="fa fa-download fa-lg" aria-hidden="true"  ></i></a>
              <i class="fa fa-times fa-lg" aria-hidden="true" onclick="CloseSound()"></i>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </v-container>
  
</template>

<script>

  const ContentsTable = () => import('@/components/ContentsTable.vue');
  const CarouselTutorial = () => import('@/components/CarouselTutorial.vue');
  // const StorageBar = () => import('@/components/StorageBar.vue');
  import { mdiInformationOutline } from '@mdi/js'

  export default {
    name: 'Contents',
    components: {
      ContentsTable,
      CarouselTutorial,
      // StorageBar
    },
    data: () => ({
      icons:{
        "informationOutline":mdiInformationOutline
      },
      contentItem:null,
      slide:false,
      object:{
        type:"object",
        title:"3Dモデル",
        id:"objectId"
      },
      image:{
        type:"image",
        title:"画像",
        id:"imageId"  
      },
      pdf:{
        type:"pdf",
        title:"PDF",
        id:"pdfId"  
      },
      sound:{
        type:"sound",
        title:"音声",
        id:"soundId"  
      },
      movie:{
        type:"movie",
        title:"動画",
        id:"movieId"  
      },
      url:{
        type:"url",
        title:"URL",
        id:"urlId"  
      }
    }),

    created : function(){
        this.contentItem=this[this.$route.name]
    },

    watch : {
      $route(){
          this.contentItem=this[this.$route.name]
      }
      
      
    }
  }
</script>


<style scoped>

  .Title > h1 {
    color: gray;
    font-weight: 400;
    border-bottom: 1px solid gray;
  }

    #play-sound{
    position: absolute;
    display: inline;
    margin:0;
    z-index:1001;
    left: 0;
    bottom:0;
    width:100%;
    height:70px;
    background: white;
    box-shadow:0 0.5px 3px 0  #ccc inset;
  }

  #play-sound-left{

    display: flex;
    padding-top:10px;
    width: 10%;
    height: 100%;
    margin: 0 auto 0 40px;
  }

  
  #play-sound-left > p  {
    margin: auto 0 auto 0;
    padding:2px  0 0 10px;
  }



  #play-sound-center{
    position: relative;
    padding-top:14px;
    width: 45%;
    height: 50px;
    margin: 0 auto 0 auto;
  }

  #play-sound-right{
    display: flex;
    padding: 10px 10px 0 0;
    width: 15%;
    height: 100%;
  
  }

  #play-sound-right > div > * {
    opacity: 0.8;
    padding:0 10px 0 10px;
  }
  
  #play-sound-right:nth-child(0){
    margin:auto 0 auto auto;
  }

  audio::-webkit-media-controls-play-button,
     audio::-webkit-media-controls-panel {
     background-color:  white;
     color: rgb(142, 142, 142); 
     opacity: 0.8;
  }

  
.v-application{
  font-family: 'Noto Sans JP', sans-serif;
}

.application {
  font-family: 'Noto Sans JP', sans-serif;
}



</style>
